<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <b-card>
          <b-card-text>
            <DxGrid
              title="GSTs"
              :data-source="dataSource"
              @on-delete="(v) => onDelete(v)"
              @on-open-modal="(v) => onManage(v)"
              @on-update-cancel="(v) => loadGSTs()"
            >
              <template #columns>
                <DxColumn
                  v-for="(item, index) in dataColumns"
                  :key="index"
                  :data-field="item.field"
                  :caption="item.caption"
                />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="onShowModal"
      centered
      @hide="onCloseModal()"
      size="lg"
      no-close-on-backdrop
    >
      <template #modal-title>
        <b-form-checkbox
          v-model="formData.active"
          checked="true"
          name="check-button"
          switch
          inline
        >
          Active
        </b-form-checkbox>
      </template>
      <b-row>
        <b-col>
          <b-form-group label="ID" label-for="v-name">
            <b-form-input v-model.number="formData.name" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Percent" label-for="v-percent">
            <b-form-input v-model.number="formData.percent" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Manual" label-for="v-manual">
            <b-form-input v-model.number="formData.manual" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Date From" label-for="v-date_for">
            <b-form-input
              id="v-date_for"
              v-model="previewDateFrom"
              type="date"
              class="form-control"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Description" label-for="v-description">
            <b-form-textarea v-model="formData.description" />
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button
          size="sm"
          @click="onCloseModal()"
          variant="danger"
          class="bg-darken-4"
        >
          Cancel
        </b-button>
        <b-button
          size="sm"
          @click="onSubmit()"
          variant="primary"
          class="bg-darken-4"
        >
          Save
        </b-button>
      </template>
    </b-modal>
  </section>
</template>

<script>
import gstApi from '@api/gsts';
import { queryParameters } from '@/schema';
// @todo change from utils classes to global-function
import { nowToLocaleDateString, UtilsDate } from '@/utils/classes/dates';

export default {
  name: 'GSTsPage',
  components: {},
  data: () => ({
    onShowModal: false,
    dataSource: [],
    dataColumns: [
      { caption: 'Name', field: 'name' },
      { caption: 'Percent', field: 'percent' },
      { caption: 'Manual', field: 'manual' },
      { caption: 'Description', field: 'description' },
      { caption: 'Date From', field: 'preview_date_from' },
    ],
    previewDateFrom: nowToLocaleDateString.split('T')[0],
    formData: {
      id: 0,
      name: '',
      description: '',
      percent: 0,
      manual: 0,
      date_from: nowToLocaleDateString.split('T')[0],
      active: true,
      status: 'O',
    },
  }),
  mounted() {
    this.loadGSTs();
  },
  methods: {
    loadGSTs() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      gstApi
        .list(params)
        .then(({ data }) => {
          const toDisplayDate = (date) => {
            return new UtilsDate(date).unixToLocaleDateString();
          };

          for (const item of data) {
            item.preview_date_from = toDisplayDate(item.date_from);
          }

          this.dataSource = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmit() {
      const toUnix = (date) => {
        if (!date) {
          return new UtilsDate().dateToUnix();
        }

        return new UtilsDate(date).dateToUnix();
      };

      const onSuccess = () => {
        this.loadGSTs();
        this.onCloseModal();
      };

      this.formData.active = this.formData.active ? '1' : '0';
      this.formData.date_from = toUnix(this.previewDateFrom);

      let app;

      if (!this.formData.id) {
        app = gstApi.add(this.formData);
      } else {
        app = gstApi.update(this.formData);
      }

      if (app) {
        app
          .then(({ data }) => {
            this.toastConfig();
            onSuccess();
          })
          .catch(() => {
            this.toastConfig('', 'error');
          })
          .finally(() => {
            //
          });
      }
    },
    onManage(event) {
      this.onShowModal = true;
      const toDisplayDate = (date) => {
        return new UtilsDate(date).unixToInputDateTime().split('T')[0];
      };

      if (event.name === 'edit') {
        this.previewDateFrom = toDisplayDate(event.data.date_from);
        this.formData = event.data;
      }
    },
    onDelete(event) {
      gstApi
        .delete(event)
        .then(({ data }) => {
          this.loadGSTs();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onCloseModal() {
      this.onShowModal = false;
      this.formData = {
        id: 0,
        name: '',
        description: '',
        percent: 0,
        manual: 0,
        active: true,
        status: 'O',
      };

      this.previewDateFrom = nowToLocaleDateString.split('T')[0];
    },
  },
};
</script>

<style></style>
